<template>
	<div id="prog-characteriscics" class="panel">
		<div class="container">
			<ul class="programm_characteriscics row">
				<!--<li class="col-12 col-sm-4 col-lg-2">
					<div class="item">
						<div class="start_label">
							Дата
							<br />старта
						</div>
						<div class="digit">{{ fetchedData.event_date | moment("DD") }}</div>
						<div class="title">
							{{ fetchedData.event_date | moment("MM") }}
							<br />
							{{ fetchedData.event_date | moment("YYYY") }}
						</div>
					</div>
				</li>-->
				<li
					v-if="'modules_count' in block.content && block.content.modules_count.ru !==null"
					class="col-6 col-sm-6 col-lg-2"
				>
					<div class="item">
						<div class="digit">
							{{ block.content.modules_count.ru }}
						</div>
						<div
							v-if="'modules_name' in block.content"
							class="title"
						>
							{{ block.content.modules_name.ru }}
						</div>
					</div>
				</li>
				<li
					v-if="'month_count' in block.content && block.content.month_count.ru !==null"
					class="col-6 col-sm-6 col-lg-2"
				>
					<div class="item">
						<div class="digit">
							{{ block.content.month_count.ru }}
						</div>
						<div
							v-if="'month_name' in block.content"
							class="title"
						>
							{{ block.content.month_name.ru }}
						</div>
					</div>
				</li>
				<li class="col-12 col-lg-7 offset-lg-1">
					<div class="item programm_properties_list">
						<div
							v-if="'schedule' in block.content && block.content.schedule.ru !==null"
							class="title"
						>
							<i class="icon-system" />
							{{ block.content.schedule.ru }}
						</div>
						<div
							v-if="'languages' in block.content && block.content.languages.ru !==null"
							class="title"
						>
							<i class="icon-language" />
							{{ block.content.languages.ru }}
						</div>
						<div
							v-if="'place' in block.content && block.content.place.ru !==null"
							class="title"
						>
							<i class="icon-place" />
							{{ block.content.place.ru }}
						</div>
						<div
							v-if="'certificate' in block.content && block.content.certificate.ru !==null"
							class="title"
						>
							<i class="icon-diploma" />
							{{ block.content.certificate.ru }}
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import '@/utils/moment';

export default {
	name: 'PanelProgramCharacteristics',
	filters: {
		declOfNum(value, titles) {
			const cases = [2, 0, 1, 1, 1, 2];
			return titles[
				value % 100 > 4 && value % 100 < 20
					? 2
					: cases[value % 10 < 5 ? value % 10 : 5]
			];
		},
	},
	props: ['block', 'fetchedData'],
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/home/prog-characteristics";
</style>
